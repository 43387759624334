.social-link img {
  filter: invert(1);
  height: 67px;
}

.social-link.light-background img {
  filter: invert(0);
}

.title-box-wrapper {
  display: flex;
}

.title-box {
  display: flex;
  align-items: flex-end;
  text-align: right;
  width: 404px;
  flex-direction: column;
}

.title-box.light-background {
  opacity: 0.2;
}

.title-box h1 {
  width: 500px;
  font-size: 70pt;
  font-family: "Source Code Pro", monospace;
  font-weight: 400;
  margin: 0;
}

.title-box h1 a {
  text-decoration: none;
}

.title-box .description {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: "Open Sans", sans-serif;
  font-size: 16pt;
}

.social-links {
  display: flex;
  flex-direction: row;
  gap: 3px;
}

@media screen and (max-width: 800px) {
  .title-box {
    width: 100%;
  }
}

@media screen and (min-width: 800px) {
  .title-box {
    margin-top: auto;
    margin-bottom: auto;
  }
}