.home-layout-columns {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  gap: 50px;
  flex-direction: row;
  justify-content: center;
  padding: 14px;
}

.content-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.blog-container {
  grid-row: 1;
  grid-column: 1 / 3;
}

.photo-art-container {
  grid-row: 2;
  grid-column: 1;
}

.engineering-container {
  grid-row: 2;
  grid-column: 2;
}

.music-container {
  grid-row: 3;
  grid-column: 1;
}

@media screen and (max-width: 800px) {
  .home-layout-columns {
    flex-direction: column;
    justify-content: start;
  }

  .content-grid {
    display: flex;
    flex-direction: column;
  }

  .blog-container {
    text-align: right;
  }

  .photo-art-container {
    text-align: right;
  }

  .engineering-container {
    text-align: right;
  }

  .music-container {
    text-align: right;
  }
}