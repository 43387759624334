.detail-view {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

.detail-view-content {
  flex: 1;
  min-width: 700px;
  padding: 20px;
}

@media screen and (max-width: 800px) {
  .detail-view {
    flex-direction: row;
  }
  
  .detail-view-content {
    flex: 1;
    min-width: 300px;
    padding: 20px;
  }
}