.App {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  font-family: 'Chivo Mono', monospace;
  line-height: normal;
  overflow-y: auto;
}

.background-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
}

.background-wrapper.dark-mode {
  background-color: black;
  color: white;
}

.background-wrapper.light-mode {
  background-color: white;
  color: black;
}

div {
  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}

h1 {
  font-size: 36pt;
  font-weight: 400;
}

h1 > a {
  color: inherit;
}

h2 {
  font-size: 32pt;
  font-family: "Source Code Pro", monospace;
}

h3 {
  font-size: 20pt;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

p {
  font-size: 13pt;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  padding-bottom: 10pt;
}

article {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.subheading {
  font-size: 14pt;
  font-family: "Open Sans", sans-serif;
}

a {
  color: inherit;
  text-decoration: underline;
  text-decoration-thickness: 2px;

  transition: opacity 0.5s linear;
}

a:hover {
  color: inherit;
  text-decoration: none;
  opacity: 0.8;
}

a img:hover {
  opacity: 0.8;
}
