.title-nav-links {
  opacity: 0.2;
  text-align: right; 
}

.detail-view-title-nav {
  margin: 35px;
}

.detail-view-sticky-nav {
  position: sticky;
  top: 0;
}

@media screen and (max-width: 800px) {
  .detail-view-title-nav {
    margin: 0;
    width: 100%;
  }
}